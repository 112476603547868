import React from "react";
import PropTypes from "prop-types";
import CookieConsent from "react-cookie-consent";

const Layout = ({ children }) => {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Got it"
        acceptOnScroll={true}
        cookieName="SLDConsentCookie"
        style={{ background: "#ea4e68" }}
        buttonStyle={{
          color: "#ea4e68",
          fontSize: "13px",
          background: "#fff",
          fontFamily: "lato",
          borderRadius: 3,
          padding: 10
        }}
      >
        <h4 className="margin-0">
          This website uses cookies{" "}
          <span role="img" aria-label="cookies">
            🍪
          </span>
          . Hope thats cool with you!
        </h4>
      </CookieConsent>
      <main>{children}</main>
      <footer class="is-white-bg is-grey pad-2 footer">
        Made with{" "}
        <span role="img" aria-label="love">
          ❤️
        </span>{" "}
        by Sam Larsen-Disney
        <p class="legal">
          All views expressed on this site are my own and do not represent the
          opinions of any entity whatsover with which I have been, am now or
          will be affilated with.
        </p>
      </footer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
